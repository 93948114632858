<template src="./AcademicPlantResearchers.html"></template>

<script>
import ArrobaMedellinSeeker from "@/components/ArrobaMedellinSeeker/ArrobaMedellinSeeker";
import idiService from "../../services/Idi";
import ChooseUniversity from "../../components/ChooseUniversity/ChooseUniversity";
import { mapGetters } from "vuex";
import ArrobaMedellinArrobitaNoData from "@/components/ArrobaMedellinArrobitaNoData/ArrobaMedellinArrobitaNoData";

export default {
  name: "academicPlantResearchers",
  components: {
    ArrobaMedellinSeeker,
    ChooseUniversity,
    ArrobaMedellinArrobitaNoData,
  },
  props: {
    TitleNotFound: {
      type: String,
      default: "Lista de investigadores",
    },
    notFound: {
      type: String,
      default: "No hay investigadores en éste momento",
    },
  },
  data() {
    return {
      errorMessage: false,
      countAcademicPlant: {},
      researchers: [],
      showMessageRemovePermissions: "",
      modalInfoRemovePermissions: false,
      modalRemovePermissions: false,
      institutionId: "",
      role: "",
      infoValidate: { relatedGroups: [] },
      infoGroups: [],
      infoUser: { name: "", lastName: "" },
      modalErrorRemovePermissions: false,
    };
  },
  created() {
    this.institutionId = this.$store.state.User.user.institutionId.id;
    this.role = this.$store.state.User.user.role.name;
    this.infoAcademicPlant();
  },
  methods: {
    buttonRemovePermissions(index) {
      this.showMessageRemovePermissions = index;
    },
    showForMessageRemovePermissions(index) {
      if (index === this.showMessageRemovePermissions) {
        return true;
      } else {
        return false;
      }
    },
    buttonBackRemovePermissions() {
      this.showMessageRemovePermissions = "";
    },
    backRemovePermissions() {
      this.modalInfoRemovePermissions = false;
      this.modalRemovePermissions = false;
      this.modalErrorRemovePermissions = false;
    },
    async infoAcademicPlant() {
      try {
        this.researchers =
          await idiService.getUserTeacherInvestigatorByInstitution(
            this.institutionId
          );
        this.countAcademicPlant = await idiService.countAcademicPlant(
          this.institutionId
        );
      } catch (error) {
        console.error(error);
      }
    },
    redirectProfileUser(path, follow) {
      let routeData = this.$router.resolve({
        name: path,
        params: { id: follow },
      });
      window.open(routeData.href, "_blank");
    },
    async validateRemovePermissions(researcher) {
      try {
        this.infoValidate = {};
        this.infoUser = researcher;
        const obj = {
          userId: this.infoUser.id.toString(),
        };
        this.infoValidate =
          await idiService.validateRemoveRoleTeacherInvestigator(obj);
        if (this.infoValidate.relatedGroups) {
          this.modalInfoRemovePermissions = true;
          this.infoGroups = this.infoValidate.relatedGroups;
        } else if (
          this.infoValidate.message ===
          "El usuario no existe o no tiene el rol requerido"
        ) {
          this.modalErrorRemovePermissions = true;
        } else {
          this.modalRemovePermissions = true;
        }
      } catch (error) {
        console.error(error);
      }
    },
    async removePermissions() {
      try {
        const obj = {
          tipo_rol: "Teacher",
        };
        await idiService.updateUserCampus(obj, this.infoUser.id);
        this.$buefy.toast.open({
          duration: 5000,
          message: "Se actualizaron los permisos correctamente",
          position: "is-bottom",
          type: "is-success",
        });
        this.infoAcademicPlant();
        this.showMessageRemovePermissions = false;
        this.backRemovePermissions();
        this.infoUser = {};
      } catch (error) {
        console.error(error);
      }
    },
  },
  computed: {
    roleValidationAdministrator() {
      return ["Administrator"].some((a) => a === this.role);
    },
    ...mapGetters({
      institution: "getInstitutionSelected",
    }),
  },
  watch: {
    institution: async function (value) {
      this.institutionId = value;
      await this.infoAcademicPlant();
    },
  },
};
</script>

<style lang="scss" scoped src="./AcademicPlantResearchers.scss"></style>